import styled from "styled-components";

const Container = styled.div`
  background-color: #19104e;
  width: 100vw;
  min-height: 100vh;
`;

const Header = styled.header`
  padding: 8px;
`;

const Logo = styled.img`
  width: 48px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 48px;
  text-align: center;
  color: white;
`;

export default function App() {
  return (
    <Container>
      <Header>
        <Logo src="haruham_full_3.png" />
      </Header>
      <Title>Haruham</Title>
    </Container>
  );
}
